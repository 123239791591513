import { withStory, WithStoryProps } from '@storyofams/storyblok-toolkit';
import { GetStaticProps, GetStaticPaths, NextPage } from 'next';

import { enhancedStaticProps, withLayout } from '~lib';
import { DynamicPage } from '~components/dynamic';
import { storyblokSdk } from '~lib/storyblok/client';
import { TermsandconditionsComponent } from '~lib/storyblok/sdk';

export interface PageProps extends WithStoryProps {
  termsAndConditions?: TermsandconditionsComponent;
  products?: any;
}

const Home: NextPage<PageProps> = ({ ...props }) => {
  return <DynamicPage {...props} />;
};


export default withLayout(withStory(Home));

export const getStaticProps: GetStaticProps<PageProps> =
  enhancedStaticProps(async ({ sdk, defaultLocale, locale }) => {
    let story;
    let notFound = false;
    let termsAndConditions = null;
    const products = {};

    try {
      const id = `${defaultLocale !== locale ? `${locale}/` : ''}home-2-0`;

      story = (
        await sdk.pageById({
          lang: defaultLocale !== locale ? locale : 'default',
          id,
        })
      ).DynamicpageItem;
    } catch (e) {
      notFound = true;
    }

    try {
      termsAndConditions = (await sdk.termsAndConditions())
        .TermsandconditionsItems;
    } catch (e) {
      notFound = true;
    }

    return {
      props: {
        products,
        story,
        termsAndConditions,
      },
      notFound: notFound || !story,
      revalidate: process.env.NEXT_PUBLIC_ENVIRONMENT === 'development' ? 1 : 60,
    };
  });
