import { Stack, SystemProps } from '@storyofams/react-ui';
import { WithStoryProps } from '@storyofams/storyblok-toolkit';
import { defineMessages, FormattedMessage } from 'react-intl';
import SbEditable from 'storyblok-react';

import { Seo, Container, Heading } from '~components';
import { TermsandconditionsComponent } from '~lib/storyblok/sdk';
import { DynamicSections } from './DynamicSections';

const messages = defineMessages({
  storyUnfold: 'Let the story unfold!',
});

export interface DynamicPageProps extends WithStoryProps, SystemProps {
  termsAndConditions?: TermsandconditionsComponent;
}

export const DynamicPage = ({ story, ...props }: DynamicPageProps) => {
  const { content } = story;

  return (
    <>
      <Seo story={story} />

      <SbEditable content={content}>
        <Stack
          alignItems="center"
          width="100%"
          flexDirection="column"
          space={[0, 0]}
          // no margin top if image first
          mt={
            content?.sections?.[0]?.component === 'image' ||
            content?.sections?.[0]?.component === 'top_banner' ||
            content?.sections?.[0]?.component === 'hero' ||
            content?.sections?.[0]?.component === 'hero_v2'
              ? 0
              : 6
          }
        >
          {!!content?.sections?.length ? (
            <DynamicSections content={content} {...props} />
          ) : (
            <Container pt={6} textAlign="center">
              <Heading as="h1" variant="h1">
                <FormattedMessage {...messages.storyUnfold} />
              </Heading>
            </Container>
          )}
        </Stack>
      </SbEditable>
    </>
  );
};
